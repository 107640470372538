import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectSections from "../../Data/Projects.json";
import images from "../../Assets/Projects/index";
import { ListGroup } from "react-bootstrap";
import Navbar from "../Navbar";
import { Card } from "react-bootstrap";
import Vimeo from '@u-wave/react-vimeo';

const STYLES = { videoIframeContainer: { padding: '100% 0 0 0', position: 'relative', }, videoIframe: { position: 'absolute', top: "15%", left: 0, width: '100%', height: "60%" } }

function Projects() {

  const [currentProject, setCurrentProject] = useState("");
  const [singleProject, showSingleProject] = useState(false);

  const showProject = (index) => {
    setCurrentProject(index);
    showSingleProject(true);
  };

  const hideSingleProject = () => {
    showSingleProject(false);
  };

  return (
    <>
    <Navbar />
    <Container fluid className="project-section">
      <p id="project-section-wip">This is page is kind of still under construction. life, it happens fast <br></br>
      Some of my <a href="https://vimeo.com/showcase/10997527">video and 3D projects are here.</a> Some are on this page</p>
      <Container>
        {
          ProjectSections.map((section, index) => {
            return (
              <>
              <p id="project-section-heading">
                {section.heading}
              </p>
              <Row xs={1} md={2} lg={4} className="g-4" style={{ justifyContent: "center", paddingBottom: "10px" }}>
                {
                  section.projects.map((project, index) => {
                    return (
                    <>
                        <Card style={{ width: '30rem' }}>
                          <Card.Body>
                            <Card.Title>{project.name}</Card.Title>
                          </Card.Body>
                          {
                            project.isImage === "true" ?                           
                            <img
                            alt="Card cap"
                            src={images[project.image]}
                            width="100%"
                            />
                            :
                            <Vimeo
                            video={project.vimeoId} 
                            />
                          }
                         
                          <Card.Body>
                            <Card.Text>
                              {project.description}
                            </Card.Text>
                          </Card.Body>
                          <Card.Body>
                            {
                              project.links?.map((link, index) => {
                                return (
                                  <>
                                  <Card.Link href={link.url}>{link.site}</Card.Link>
                                  </>
                                );
                              })
                            }
                          </Card.Body>
                        </Card>
                    </>
                  );
                  })
                }
              </Row>              
              </>
            )
          })
        }
      </Container>
    </Container>
    <Container fluid>
    {/* {
      singleProject ? 
      <div id="single-project-wrapper" onClick={hideSingleProject}>
        <div id="single-project">
          <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="holder.js/100px180?text=Image cap" />
            <Card.Body>
              <Card.Title>{PROJECTS[currentProject].name}</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up the
                bulk of the card's content.
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Card.Link href="#">Card Link</Card.Link>
              <Card.Link href="#">Another Link</Card.Link>
            </Card.Body>
          </Card>          
        </div>

      </div> 
      : ""
    } */}
  </Container>    
    </>

  );
}

export default Projects;
