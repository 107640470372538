import lusakamuseum from "../Projects/lusakamuseum.png";
import sparkar from "../Projects/sparkar.png";
import birds from "../Projects/birds.png";




export default {
    lusakamuseum,
    sparkar,
    birds
}
