import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import headgif from '../../Assets/me-website-2024-final.gif';
import { Link } from "react-router-dom";
import plants from "../../Assets/PLANTS v1-Test.pdf";
import {
  AiFillGithub,
} from "react-icons/ai";

function Home() {
  return (
    <Container fluid className="home-section" id="home">
      <Container className="home-content">
        <Row>
        <Col md = {{span: 8, order:1}} xs={{span: 12, order:2}} className="home-about-description">
        <span>
        <p>
          Greetings, my name is Elijah <br></br>
          I'm a software engineer, artist, and designer <br></br>
        </p>
        <p>
          This website, my little corner of the internet, is under construction yet again.
        </p>
        <p>
          If you need to reach me, you can do so <a href="https://www.instagram.com/modeaxe">here,</a> <a href="mailto:ez2294@columbia.edu">here,</a> or <a href="https://www.linkedin.com/in/elijahzulu/">here</a>
        </p>
        <p>
        One recent <Link to="/livingstatues">project</Link>
        </p>
        </span>
            {/* <p>
              Greetings, my name is Elijah
            </p>
            <p>
              I'm a software engineer and designer <br></br>
              I am currently a resident artist (April - July 2024) with the <a href="https://publicartfutureslab.com/">Public Art Futures Lab</a>. <Link to="/livingstatues">This is what I'm making</Link><br></br><br></br>
              This website used to be ironic and self-aware in a sort of tongue-in-cheek way, but I changed it.<br></br>
            </p>
            <p>
            Here's a brief, sincere list of things instead:
              <ul>
              <li>
                When I'm not SWE-ing (day job), I like to work on projects using 3D, VR, AR, video & data  
              </li>                
              <li>
                You can find <Link to="/project">some of my projects here</Link> and others on a hard drive somewhere in my apartment
              </li>
              <li>
                Feel free to reach me via <a href="mailto:ez2294@columbia.edu">email</a> or <a href="https://www.linkedin.com/in/elijahzulu/">linkedin</a>
              </li>
              <li>
              I am the inventor of this combination of emojis (&#128016;&#128016;&#128016;&#128016;&#x1FAE1;) I call it the four goat salute. You are allowed to use it
              </li>
              <li>
                I also occasianally create ambient music under the pseudonym <a href="https://open.spotify.com/artist/703H847RxODEIJpgH4ugIg">omedexa</a> (i don't know what it means)
              </li>
              <li>
                If I'm not doing any of those things, I'm probably taking <a href={plants} target="_blank" rel="noreferrer noopener">pictures of plants</a>
              </li>
              <li>
                In 2020, I had a series of dreams that I couldn't stop thinking about until I recreated them using a computer. Computers can do pretty cool things. <a href="https://www.elijahzulu.com/GalleryWEBGL/">First dream.</a> <a href="https://www.elijahzulu.com/avemaria/">Second dream.</a>
              </li>
            </ul>              
            </p>
              I hope you have a great day &lt;3
            <p>
              
            </p>                */}
          </Col>       
        </Row>
        {/* <Row>
          <Col md={12} className="home-about-social">
            <p>
              Feel free to reach me via <a href="mailto:ez2294@columbia.edu">email</a> or <a href="https://www.linkedin.com/in/elijahzulu/">linkedin</a>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/modeaxe"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
            </ul>
          </Col>
        </Row> */}
      </Container>
    </Container>      
  );
}

export default Home;
